<template>
  <section>
    <div class="headerjob">
      <v-container class="container-custom">
        <v-row>
          <v-col> <h1 class="titlepage">Add Your Details</h1></v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: "SinglePage",
  data:()=>({

  }),
  
};
</script>

<style lang="scss" scoped></style>
